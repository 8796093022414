import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import KickstarterLogo from './kickstarter-dark.png'

const styles = {
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#05cd78',
    padding: '50px 0',
  },
  title: {
    color: '#fff',
    fontSize: 40,
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Bold, Helvetica Nueue, sans-serif',
  },
  img: {
    display: 'block',
    height: 40,
    marginTop: -20,
  },
}

export const Kickstarter = () =>
  <div style={styles.section}>
    <style>
      {
        `
          .btn-kickstarter {
            float: right;
            padding: 20px 30px !important;
            box-shadow: 0px 1px 15px -1px rgba(0, 0, 0, 0.75);
          }

          @media (max-width: 768px) {
            .kickstarter-right-col {
              margin-top: 25px;
              text-align: center !important;
            }

            .btn-kickstarter {
              float: none;
              display: block;
            }
          
            .kickstarter-left-col {
              text-align: center;
            }
          
            .kickstarter-left-col span {
              text-align: center;
            }

            .kickstarter-left-col img {
              display: block;
              margin: 0 auto !important;
            }
          }
        `
      }
    </style>

    <Container>
      <Row>

        <Col xs={12} md={8} className={'kickstarter-left-col'}>
          <div style={styles.title}>
            SUPPORT US ON
          </div>
          <img src={KickstarterLogo} alt={'Kickstarter'} style={styles.img} className={'kickstarter-logo'}/>
        </Col>

        <Col xs={12} md={4} className={'kickstarter-right-col'}>
          <a href={'https://bit.ly/3er8Kuq'} target={'_blank'} className={'btn btn-light btn-kickstarter mt-2'}>Back This Project</a>
        </Col>

      </Row>
    </Container>
  </div>
