import React from 'react'
import { Intro } from './intro/Intro'
import { Kickstarter } from './kickstarter/Kickstarter'
import { SectionFlashers } from './flashers/SectionFlashers'
import { SectionConvertible } from './convertible/SectionConvertible'
import { Statistics } from './statistics/Statistics'
import { SectionTowing } from './towing/SectionTowing'
import { MailChimp } from './mailchimp/MailChimp'

export const Home = () =>
  <>
    <Intro />
    <Kickstarter />
    <SectionFlashers />
    <SectionConvertible />
    <Statistics />
    <SectionTowing />
    <MailChimp />

  </>
